@use 'vars' as *;

.btn{
    $size: 20px;

    height: $size;
    width: $size;
    padding:2px;
    background-image: url('../img/icon-btn.png');
    border: none;

    &:hover{
        background-image: url('../img/icon-btn-hover.png');
    }

    &:active{
        background-image: url('../img/icon-btn-active.png');
    }

    .icon{
        $iconSize: 16px;

        height: $iconSize;
        width: $iconSize;
        background-position: center;
    }

    &-clicker{
        position: relative;
        height: 72px;
        width: 192px;
        background-color: #7d7071;
        background-image: url('../img/btn-bg.svg');
        cursor: pointer;
        user-select: none;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
            background-color: #a0938e;
            background-image: url('../img/btn-bg-hover.svg');
        }

        &:active{
            background-color: #7d7071;
            background-image: url('../img/btn-bg.svg');
        }

        &[disabled="true"]{
            pointer-events: none;
            filter: grayscale(80%);
            background-image: url('../img/btn-bg-off.svg');
        }

        .icon{
            position: absolute;
            left: 1px;
            height: 64px;
            width: 64px;

            $icons: "autoclicker","mouse","cow","mine","hedgefund";

            @each $icon in $icons {
                &-#{$icon}{
                    background-image: url('../img/icon-#{$icon}.svg');
                }
            }
        }

        .label{
            position: absolute;
            right: 10px;
            &-amount{
                position: absolute;
                right: 0.5ch;
                bottom: 0;
                text-align: right;
                font-weight: bold;
                font-size: 24px;
                font-family: $font-rubik;
                color: white;

                text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
            }

            &-cost{
                font-size: 10px;
                position: absolute;
                right: 10px;
                top: 0.5em;
            }
        }
    }
}

#saveBtn{
    .icon{
        background-image: url('../img/icon-save.png');
    }
}

#resetSaveBtn{
    .icon{
        background-image: url('../img/icon-reset.png');
    }
}